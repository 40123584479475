<template>
  <div>
    <PageTitle v-bind:title="'References'" />

    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col>
          <b-button v-if="config.editable" variant="primary" size="sm" :disabled="!$store.getters.checkRole('legam.dict.reference.add')" class="float-right" v-b-modal.modal-new-reference>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new reference
          </b-button>
          <b-modal v-if="config.editable" id="modal-new-reference" title="New reference" ok-title="Save" @ok="saveReference" size="lg">
            <ReferenceNew />
          </b-modal>
          <LegamDataTables v-if="config.editable" v-bind="$data" :searchableByColumn="true" />
          <LegamDataTablesClientSide v-else v-bind="$data" :searchableByColumn="true" @click="openDetails" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 150px;
  }

  * >>> tr.orange td {
    color: #ff6600;
  }
</style>

<script>
  import tdColSafeHTML from '@/components/tdColSafeHTML.vue'
  import tdColAction from '@/components/tdColAction.vue'
  import tdColUUID from '@/components/tdColUUID.vue'
  import PageTitle from '@/components/PageTitle.vue'
  import ReferenceNew from '@/components/ReferenceNew.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import router from '@/router'
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'ReferenceList',
    data(){
      return {
        columns: [
          { title: 'ID', field: 'id', sortable: true, tdComp: tdColUUID, searchable: true },
          { title: this.$t('SurfaceForm'), field: 'surfaceForm', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: this.$t('Siglum'), field: 'siglum', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          // { title: 'Study', field: 'studySigleLegam', sortable: true, tdComp: tdColSafeHTML, searchable: true, transformData: function(row){
          //     return row.studyIds.join(', ')
          //   }
          // },
          { title: this.$t('Context'), field: 'context', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Details', field: 'action', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function(row){
              let retVal = [
                {text: null, icon: 'list-ul', id: row.id, to: {name: 'ReferenceDetails', params: {id: row.id}}}
              ]
              return retVal
            }
          }
        ],
        columnsPI: [
          { title: 'Surface form', field: 'surfaceForm', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Siglum', field: 'siglum', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          // { title: 'Study', field: 'studySigleLegam', sortable: true, tdComp: tdColSafeHTML, searchable: true, transformData: function(row){
          //     return row.studyIds.join(', ')
          //   }
          // },
          { title: 'Context', field: 'context', sortable: true, tdComp: tdColSafeHTML, searchable: true }
        ],
        url: '/references/datatables',
        config: config,
        trFunction: function(row, data){
          if (data.token){
            row.classList.add("orange")
          }
        }
      }
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      ReferenceNew
    },
    methods: {
      saveReference(){
        this.$store.dispatch('createReference', HelpStore.item).then((response) => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Reference created`
          })
          router.push(`/reference/${response.data.id}`)
        })
      },
      openDetails(data) {
        router.push(`/reference/${data.id}`)
      },
    }
  }
</script>
