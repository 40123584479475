<template>
  <div class="studies" id="select-study">
    <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'

  export default {
    name: 'StudySelect',
    props: ['exclude', 'study'],
    components: {
      Select2
    },
    mounted() {
      if (this.study){
        this.$refs.select2.select2.append(
          `<option selected value="${this.study.id}">${this.study.sigleLegam}</option>`
        ).trigger('change')
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a study",
          allowClear: true,
          width: '100%',
          dropdownParent: "#select-study",
          ajax: {
            delay: 250,
            url: config.apiUrl + '/studies/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term
              }
            },
            processResults: (data) => {
              return {
                results: data.map(study => {
                  return {
                    id: study.id,
                    text: study.sigleLegam,
                    study: study
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        this.$emit('select', event.selected ? event.study : null)
      }
    }
  }
</script>
